import ars from '@assets/clubs/ars.webp';
import bel from '@assets/clubs/bel.webp';
import bsu from '@assets/clubs/bsu.webp';
import dbr from '@assets/clubs/dbr.webp';
import gom from '@assets/clubs/gom.webp';
import isl from '@assets/clubs/isl.webp';
import min from '@assets/clubs/min.webp';
import naf from '@assets/clubs/naf.webp';
import nem from '@assets/clubs/nem.webp';
import sha from '@assets/clubs/sha.webp';
import sla from '@assets/clubs/sla.webp';
import slu from '@assets/clubs/slu.webp';
import smo from '@assets/clubs/smo.webp';
import tbz from '@assets/clubs/tbz.webp';
import vit from '@assets/clubs/vit.webp';
import bate from '@assets/clubs/bate.webp';
import dmn from '@assets/clubs/dmn.webp';
import bvb from '@assets/clubs/bvb.webp';
import manchester from '@assets/clubs/manchester.webp';
import bayern from '@assets/clubs/bayern.webp';
import newcastle from '@assets/clubs/newcastle.webp';
import fiorentina from '@assets/clubs/fiorentina.webp';
import manchester_united from '@assets/clubs/manchester_united.webp';
import chelsea from '@assets/clubs/chelsea.webp';
import liverpool from '@assets/clubs/liverpool.webp';
import arsenal from '@assets/clubs/arsenal.webp';
import tottenham from '@assets/clubs/tottenham.webp';
import realmadrid from '@assets/clubs/realmadrid.webp';
import barcelona from '@assets/clubs/barcelona.webp';
import juventus from '@assets/clubs/juventus.webp';
import bvl from '@assets/clubs/bvl.webp';
import milan from '@assets/clubs/milan.webp';

const CLUBS = [
    {
        name: 'Высшая лига',
        logo: bvl,
        id: 'bvl',
        color: 'red',
        city: 'Беларусь',
        country: 'Беларусь',
        shortName: 'БВЛ'
    },
    {
        name: 'Арсенал',
        logo: ars,
        id: 'ars',
        color: 'red',
        city: 'Держинск',
        country: 'Беларусь',
        shortName: 'АРС'
    },
    {
        name: 'Белшина',
        logo: bel,
        id: 'bel',
        color: 'red',
        city: 'Бобруйск',
        country: 'Беларусь',
        shortName: 'БЕЛ'
    },
    {
        name: 'РЦОР БГУ',
        logo: bsu,
        id: 'bsu',
        color: 'red',
        city: 'Минск',
        country: 'Беларусь',
        shortName: 'БГУ'
    },
    {
        name: 'Динамо-Брест',
        logo: dbr,
        id: 'dbr',
        color: 'red',
        city: 'Брест',
        country: 'Беларусь',
        shortName: 'ДБР'
    },
    {
        name: 'Гомель',
        logo: gom,
        id: 'gom',
        color: 'red',
        city: 'Гомель',
        country: 'Беларусь',
        shortName: 'ГОМ'
    },
    {
        name: 'Ислочь',
        logo: isl,
        id: 'isl',
        color: 'red',
        city: 'Минская обл.',
        country: 'Беларусь',
        shortName: 'ИСЛ'
    },
    {
        name: 'Минск',
        logo: min,
        id: 'min',
        color: 'red',
        city: 'Минск',
        country: 'Беларусь',
        shortName: 'МИН'
    },
    {
        name: 'Нафтан',
        logo: naf,
        id: 'naf',
        color: 'red',
        city: 'Борисов',
        country: 'Беларусь',
        shortName: 'БАТ'
    },
    {
        name: 'Неман',
        logo: nem,
        id: 'nem',
        color: 'red',
        city: 'Гродно',
        country: 'Беларусь',
        shortName: 'НЕМ'
    },
    {
        name: 'Шахтер',
        logo: sha,
        id: 'sha',
        color: 'red',
        city: 'Солигорск',
        country: 'Беларусь',
        shortName: 'ШАХ'
    },
    {
        name: 'Славия',
        logo: sla,
        id: 'sla',
        color: 'red',
        city: 'Мозырь',
        country: 'Беларусь',
        shortName: 'СЛА'
    },
    {
        name: 'Слуцк',
        logo: slu,
        id: 'slu',
        color: 'red',
        city: 'Слуцк',
        country: 'Беларусь',
        shortName: 'СЛУ'
    },
    {
        name: 'Сморгонь',
        logo: smo,
        id: 'smo',
        color: 'red',
        city: 'Сморгонь',
        country: 'Беларусь',
        shortName: 'СМО'
    },
    {
        name: 'Торпедо БелАЗ',
        logo: tbz,
        id: 'tbz',
        color: 'red',
        city: 'Жодино',
        country: 'Беларусь',
        shortName: 'ТБЗ'
    },
    {
        name: 'Витебск',
        logo: vit,
        id: 'vit',
        color: 'red',
        city: 'Витебск',
        country: 'Беларусь',
        shortName: 'ВИТ'
    },
    {
        name: 'БАТЭ',
        logo: bate,
        id: 'bate',
        color: 'red',
        city: 'Борисов',
        country: 'Беларусь',
        shortName: 'БАТ'
    },
    {
        name: 'Динамо-Минск',
        logo: dmn,
        id: 'dmn',
        color: 'blue',
        city: 'Минск',
        country: 'Беларусь',
        shortName: 'ДМН'
    },
    {
        name: 'Borussia Dortmund',
        shortName: 'BVB',
        logo: bvb,
        id: 'bvb',
        city: 'Dortmund',
        country: 'Germany',
        color: 'accent'
    },
    {
        name: 'Manchester City',
        shortName: 'Man. City',
        logo: manchester,
        id: 'mancity',
        city: 'Manchester',
        country: 'UK',
        color: 'blue'
    },
    {
        name: 'Bayern Munich',
        logo: bayern,
        id: 'bayern',
        city: 'Munich',
        country: 'Germany',
        color: 'red',
        shortName: 'Bayern'
    },
    {
        name: 'Newcastle United',
        logo: newcastle,
        id: 'newcastle',
        city: 'Newcastle',
        country: 'UK',
        shortName: 'Newcastle',
        color: 'blue'
    },
    {
        name: 'Fiorentina',
        logo: fiorentina,
        id: 'fiorentina',
        city: 'Florence',
        country: 'Italy',
        shortName: 'Fiorentina',
        color: 'purple'
    },
    {
        name: 'Manchester United',
        logo: manchester_united,
        id: 'manunited',
        color: 'red',
        city: 'Manchester',
        country: 'UK',
        shortName: "Man. United"
    },
    {
        name: 'Chelsea',
        logo: chelsea,
        id: 'chelsea',
        color: 'blue',
        city: 'London',
        country: 'UK',
        shortName: 'Chelsea'
    },
    {
        name: 'Liverpool',
        logo: liverpool,
        id: 'liverpool',
        color: 'turquoise',
        city: 'Liverpool',
        country: 'UK',
        shortName: 'Liverpool'
    },
    {
        name: 'Arsenal',
        logo: arsenal,
        id: 'arsenal',
        color: 'red',
        city: 'London',
        country: 'UK',
        shortName: 'Arsenal'
    },
    {
        name: 'Tottenham Hotspur',
        logo: tottenham,
        id: 'tottenham',
        color: 'blue',
        city: 'London',
        country: 'UK',
        shortName: 'Tottenham'
    },
    {
        name: 'Real Madrid',
        logo: realmadrid,
        id: 'realmadrid',
        color: 'accent',
        city: 'Madrid',
        country: 'Spain',
        shortName: 'Real Madrid'
    },
    {
        name: 'Barcelona',
        shortName: 'Barca',
        logo: barcelona,
        id: 'barcelona',
        color: 'blue',
        city: 'Barcelona',
        country: 'Spain'
    },
    {
        name: 'Juventus',
        logo: juventus,
        id: 'juventus',
        color: 'grass',
        city: 'Turin',
        country: 'Italy',
        shortName: 'Juventus'
    },
    {
        name: 'AC Milan',
        logo: milan,
        id: 'acmilan',
        color: 'red',
        city: 'Milan',
        country: 'Italy',
        shortName: 'AC Milan'
    },
    {
        name: 'Juventus',
        logo: juventus,
        id: 'juventus',
        color: 'grass',
        city: 'Turin',
        country: 'Italy',
        shortName: 'Juventus'
    },
    {
        name: 'AC Milan',
        logo: milan,
        id: 'milan',
        color: 'red',
        city: 'Milan',
        country: 'Italy',
        shortName: 'AC Milan'
    },
];

export default CLUBS